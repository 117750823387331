$(document).ready(function () {
    $('.navbar-toggler').click(function () {
        $(this).find('.navbar-toggler-icon').toggleClass('open');
        if ($(this).hasClass('open')) {
            $(this).find('.texte').text('FERMER');
        } else {
            $(this).find('.texte').text('MENU');
        }
    });
});

/**
 * Search form
 */
const formSearch = document.querySelectorAll('.form_search');
formSearch.forEach(form => {
    form.addEventListener('submit', function (event) {
        const text = this.querySelector('input[type=text]');
        let textValue = text.value;
        if (isHidden(text)) {
            event.preventDefault();
            text.style.display = 'block';
            if (text.classList.contains('fadeOutRight')) {
                text.classList.remove('animated', 'fadeOutRight', 'faster');
            }
            text.classList.add('fadeInRight', 'animated', 'faster');
        } else if (textValue == "") {
            event.preventDefault();
            if (text.classList.contains('fadeInRight')) {
                text.classList.remove('animated', 'fadeInRight', 'faster');
            }
            text.classList.add('fadeOutRight', 'animated', 'faster');
            setTimeout(function () {
                text.style.display = 'none'
            }, 500)
        }
    })
});

/**
 * Add class sticky to body
 */
function getScrollPosition() {
    const body = document.querySelector('body');
    let scrollObject = {
        x: window.pageXOffset,
        y: window.pageYOffset
    }
    if (scrollObject.y > 200) {
        body.classList.add('sticky')
    } else {
        body.classList.remove('sticky')
    }
}
window.onscroll = getScrollPosition;

/**
 * Function to check if is hidden
 * @param el HTML Element
 */
function isHidden(el) {
    return (el.offsetParent === null);
}
