/**
 * Banner
 */
const bannerLink = document.querySelectorAll('.banner-menu a');
bannerLink.forEach(link => {
    link.addEventListener('click', function (event) {
        const linkMenu = this.dataset.menu;
        const bannerMenu = document.querySelectorAll('.banner-menu-links');
        bannerMenu.forEach(menu => {
            menu.classList.remove('active');
        });
        const bannerMenuActive = document.querySelector('.banner-menu-' + linkMenu);
        bannerMenuActive.classList.add('active')
    })
});
const buttonCloseMenu = document.querySelectorAll('.banner-menu-links .fermer');
buttonCloseMenu.forEach(button => {
    button.addEventListener('click', function (event) {
        const bannerMenu = document.querySelectorAll('.banner-menu-links');
        bannerMenu.forEach(menu => {
            menu.classList.remove('active');
        });
    });
});